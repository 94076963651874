.App {
    
    width: 100%;
    height: 100vh;
    box-sizing: border-box;

    .Landscape{

        height: calc(100vh - 5.72vw);

        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;

        .Columna1{

            width: 61.92%;
            height: 100%;

        }

        .Columna2{

            width: calc(100% - 61.92% );
        }
    }
}