.container{
    width: 100%;
    height: 14.44vw;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #000;
    background-color: #eee;
    padding: 0 4.62vw;
    box-sizing: border-box;
    justify-content: space-between;

    @media (orientation: landscape){

        width: 100%;
        height: 5.72vw;
    }

    img{
        width: 24.72vw;
        height: auto;

        @media (orientation: landscape){

            width: 11.19vw;
            height: auto;
        }
    }
}

