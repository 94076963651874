.landscape{

    .item{

        width: 100%;
        // height: 46.29vw;
        height: 37.037vw;
        border: 3px solid #000;
        position: relative;
        box-sizing: border-box;
        
        &:first-of-type{

            margin-bottom: 2.08vw;
            
        }
        
        @media (orientation: landscape){

            width: 35.98vw;
            height: 14.73vw;
        }  

        img, video{

            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; 
            
        }

        .text{
            
            position: absolute;
            top: 3.70vw;
            left: 2.77vw;
            font-family: 'Knockout HTF49';
            font-size: 5.55vw;
            color: #fff;
            text-transform: uppercase;

            @media (orientation: landscape){

                font-size: 2.13vw;
                top: 1.45vw;
                left: 1.19vw;
            } 
        }

    }

}