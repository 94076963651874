.Reloj{
    
    font-family: 'Knockout HTF49';
    color: #000;
    font-size: 7.40vw;

    @media (orientation: landscape){

        font-size: 3.125vw;
    }
}