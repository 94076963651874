.container{

    @media (orientation: landscape){

        position: relative;

    }

    h1{
        font-family: 'Knockout HTF49';
        font-size: 9.25vw;
        font-weight: normal;
        color: #fff;
        margin-top: 4.16vw;
        margin-bottom: 11.57vw;
        padding: 0 4.62vw;

        @media (orientation: landscape){

           font-size: 4.68vw;
           margin-top: 1.87vw;
           margin-bottom: 1.64vw;
           padding: 0 2.23vw;

        }
    }

    .mapa{

        text-align: center;
        margin-bottom: 5.5vw;

        @media (orientation: landscape){

            margin-bottom: 0;
        } 

        img{
            max-width: 95.27vw;
            height: 62.96vw;

            &[data-mapa="ground"]{
                height: 59.5vw;
                margin-bottom: calc(62.96vw - 59.5vw);

                @media (orientation: landscape){

                    height: 36.2vw;
                    margin-bottom: calc(38.43vw - 36.2vw);
                  
                } 
            }

            @media (orientation: landscape){

                width: 57.86vw;
                height: 38.43vw;
              
            }     
        }
    }

    .selector{

            display: flex;
            align-items: center;
            box-sizing: border-box;
            justify-content: center;
            margin-bottom: 9vw;

            @media (orientation: landscape){

                position: absolute;
                flex-direction: column;
                margin-bottom: 0;
                right: 8.54vw;
                bottom: 1.66vw;
        
            }

        .item{

            opacity: 0.4;
            transition: 0.5s;

            &.active{
                opacity: 1;
            }

            &:first-of-type{
                margin-right: 3.24vw;

                @media (orientation: landscape){

                    margin-right: 0;
                    order: 2;
                    bottom: 1.66vw;
                }
            }

            &:last-of-type{

                @media (orientation: landscape){

                    order: 1;
                    
                }
            }
            
            .icon{

                img{
                    width: 100%;
                }
                width: 12.03vw;
                margin-bottom: 1.5vw;
                margin-left: auto;
                margin-right: auto;

                @media (orientation: landscape){

                    width: 6.77vw;
                    height: auto;
                    margin-bottom: 0.04vw;
                }  
                
            }
    
            .text{
                
                font-family: 'Knockout HTF52';
                font-size: 2.31vw;
                color: #000;
                text-align: center;

                @media (orientation: landscape){

                    font-size: 1.30vw;
                } 
              
            }
        }
        
    }
}